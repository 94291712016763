import * as React from "react"
import '../../style.css'
import PPTCarousel from "../../components/carousel";
import Layout from "../../components/layout"

const swec = () => {
    const info ={
        title:"Swift Education Committee October 2003",
        presenter:'Dr. Lynn Cominsky',
        presentationType:'SwEC Report',
        folder:'powerpoints/Swift_Education_committee_oct_2003',
        folderLength:18,
        slides:null,
        ppt:'swec_101903.ppt',
        pptx:'swec_101903.pptx'
    }

    let slides=[]
    for(let i = 1; i <= info.folderLength; i++){
        slides.push(`/${info.folder}/Slide${i}`);
    }

    return (
        <Layout>
                <h1>{info.title}</h1>
                <h2>{info.presenter}</h2>
                <PPTCarousel slides={slides}/>
                <p>Download the slides as a <a href={`/${info.folder}/${info.ppt}`} download target='_blank' rel="noreferrer">ppt</a> or <a href={`/${info.folder}/${info.ppt}`} download target='_blank' rel="noreferrer">pptx</a></p>
        </Layout>
  )
}

export default swec